import React from "react"
import { Layout } from "@components/layout"
import PostItem from "../blog/PostItem"
import { tags, tags__headers, tags__items } from "./styles/tags.module.scss"
const Tag = ({ pageContext }) => {
  const { page, tag, posts } = pageContext

  return (
    <Layout {...page}>
      <div className={tags}>
        <h1>#{tag.name}</h1>
        <div className={tags__items}>
          {posts.map((el, k) => (
            <PostItem {...el} key={k} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Tag
